import * as React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Gallery } from '../../core/Gallery';

export const ArtistSection = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });

  const handleUserKeyPress = (event) => {
    const { key } = event;

    if (key === 'Escape') {
      navigate('/portfolio/events');
    }
  };

  return <Gallery images={location.state.images} />;
};
