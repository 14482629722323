import * as React from 'react';

import './Gallery.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faAngleRight,
  faAngleLeft
} from '@fortawesome/free-solid-svg-icons';

export const Gallery = ({ images }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [activeIndex, setIndex] = React.useState(0);

  function next() {
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setIndex(nextIndex);
  }

  function previous() {
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setIndex(nextIndex);
  }

  function openModal(index) {
    setIndex(index);
    setModalIsOpen(true);
  }

  return (
    <div className="gallery-container container">
      <div className="gallery-row">
        {images.map((photo, index) => (
          <div key={index} className="gallery-column">
            <img
              src={photo.src}
              alt={photo.alt}
              onClick={() => openModal(images.indexOf(photo))}
            />
          </div>
        ))}
      </div>

      <div className={modalIsOpen ? 'modal-img-open' : 'modal-img'}>
        <div className="content-img">
          <button onClick={() => previous()}>
            <FontAwesomeIcon icon={faAngleLeft} size="4x" />
          </button>
          <img src={images[activeIndex].src} alt={images[activeIndex].alt} />
          <button onClick={() => next()}>
            <FontAwesomeIcon icon={faAngleRight} size="4x" />
          </button>
        </div>
        <button className="modal-close" onClick={() => setModalIsOpen(false)}>
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
    </div>
  );
};
