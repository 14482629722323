const principalImages = require.context(
  '../../../assets/images/eventos/principales',
  true
);
const otherImages = require.context(
  '../../../assets/images/eventos/otros',
  true
);

export const photos = [
  {
    key: 1,
    src: principalImages(`./tangana_1.jpg`),
    alt: 'tangana',
    title: 'C. Tangana',
    isPrincipal: true,
    order: 1
  },
  {
    key: 2,
    src: principalImages(`./tangana_2.jpg`),
    alt: 'tangana_1',
    title: 'C. Tangana',
    isPrincipal: true,
    order: 1
  },
  {
    key: 3,
    src: principalImages(`./tangana_3.jpg`),
    alt: 'tangana_2',
    title: 'C. Tangana',
    isPrincipal: true,
    order: 1
  },
  {
    key: 4,
    src: principalImages(`./beckyG_1.jpg`),
    alt: 'beckyG_1',
    title: 'Becky G',
    isPrincipal: true,
    order: 6
  },
  {
    key: 5,
    src: principalImages(`./beckyG_2.jpg`),
    alt: 'beckyG_2',
    title: 'Becky G',
    isPrincipal: true,
    order: 6
  },
  {
    key: 6,
    src: principalImages(`./beckyG_3.jpg`),
    alt: 'beckyG_3',
    title: 'Becky G',
    isPrincipal: true,
    order: 6
  },
  {
    key: 7,
    src: principalImages(`./beckyG_4.jpg`),
    alt: 'beckyG_4',
    title: 'Becky G',
    isPrincipal: true,
    order: 6
  },
  {
    key: 8,
    src: principalImages(`./beckyG_5.jpg`),
    alt: 'beckyG_5',
    title: 'Becky G',
    isPrincipal: true,
    order: 6
  },
  {
    key: 9,
    src: principalImages(`./beckyG_6.jpg`),
    alt: 'beckyG_6',
    title: 'Becky G',
    isPrincipal: true,
    order: 6
  },
  {
    key: 10,
    src: principalImages(`./beckyG_7.jpg`),
    alt: 'beckyG_7',
    title: 'Becky G',
    isPrincipal: true,
    order: 6
  },
  {
    key: 11,
    src: principalImages(`./beckyG_8.jpg`),
    alt: 'beckyG_8',
    title: 'Becky G',
    isPrincipal: true,
    order: 6
  },
  {
    key: 12,
    src: principalImages(`./beckyG_9.jpg`),
    alt: 'beckyG_9',
    title: 'Becky G',
    isPrincipal: true,
    order: 6
  },
  {
    key: 13,
    src: principalImages(`./beckyG_10.jpg`),
    alt: 'beckyG_10',
    title: 'Becky G',
    isPrincipal: true,
    order: 6
  },
  {
    key: 14,
    src: principalImages(`./beckyG_11.jpg`),
    alt: 'beckyG_11',
    title: 'Becky G',
    isPrincipal: true,
    order: 6
  },
  {
    key: 15,
    src: principalImages(`./beckyG_12.jpg`),
    alt: 'beckyG_12',
    title: 'Becky G',
    isPrincipal: true,
    order: 6
  },
  {
    key: 16,
    src: otherImages(`./alexLumbier_1.jpg`),
    alt: 'alexLumbier_1',
    title: 'Alex Lumier',
    isPrincipal: false
  },
  {
    key: 17,
    src: otherImages(`./alexLumbier_2.jpg`),
    alt: 'alexLumbier_2',
    title: 'Alex Lumier',
    isPrincipal: false
  },
  {
    key: 18,
    src: otherImages(`./eladioCarrion_1.jpg`),
    alt: 'eladioCarrion_1',
    title: 'Eladio Carrión',
    isPrincipal: false
  },
  {
    key: 19,
    src: otherImages(`./eladioCarrion_2.jpg`),
    alt: 'eladioCarrion_2',
    title: 'Eladio Carrión',
    isPrincipal: false
  },
  {
    key: 20,
    src: otherImages(`./eladioCarrion_3.jpg`),
    alt: 'eladioCarrion_3',
    title: 'Eladio Carrión',
    isPrincipal: false
  },
  {
    key: 21,
    src: otherImages(`./li4m_1.jpg`),
    alt: 'li4m_1',
    title: 'LI4M',
    isPrincipal: false
  },
  {
    key: 22,
    src: otherImages(`./li4m_2.jpg`),
    alt: 'li4m_2',
    title: 'LI4M',
    isPrincipal: false
  },
  {
    key: 23,
    src: otherImages(`./li4m_3.jpg`),
    alt: 'li4m_3',
    title: 'LI4M',
    isPrincipal: false
  },
  {
    key: 24,
    src: principalImages(`./quevedo_1.jpg`),
    alt: 'quevedo_1',
    title: 'Quevedo',
    isPrincipal: true,
    order: 2
  },
  {
    key: 25,
    src: principalImages(`./quevedo_2.jpg`),
    alt: 'quevedo_2',
    title: 'Quevedo',
    isPrincipal: true,
    order: 2
  },
  {
    key: 26,
    src: principalImages(`./quevedo_3.jpg`),
    alt: 'quevedo_3',
    title: 'Quevedo',
    isPrincipal: true,
    order: 2
  },
  {
    key: 27,
    src: principalImages(`./quevedo_4.jpg`),
    alt: 'quevedo_4',
    title: 'Quevedo',
    isPrincipal: true,
    order: 2
  },
  {
    key: 28,
    src: principalImages(`./quevedo_5.jpg`),
    alt: 'quevedo_5',
    title: 'Quevedo',
    isPrincipal: true,
    order: 2
  },
  {
    key: 29,
    src: principalImages(`./quevedo_6.jpg`),
    alt: 'quevedo_6',
    title: 'Quevedo',
    isPrincipal: true,
    order: 2
  },
  {
    key: 30,
    src: principalImages(`./quevedo_8.jpg`),
    alt: 'quevedo_8',
    title: 'Quevedo',
    isPrincipal: true,
    order: 2
  },
  {
    key: 31,
    src: principalImages(`./quevedo_9.jpg`),
    alt: 'quevedo_9',
    title: 'Quevedo',
    isPrincipal: true,
    order: 2
  },
  {
    key: 32,
    src: principalImages(`./quevedo_10.jpg`),
    alt: 'quevedo_10',
    title: 'Quevedo',
    isPrincipal: true,
    order: 2
  },
  {
    key: 33,
    src: principalImages(`./snow_1.jpg`),
    alt: 'snow_1',
    title: 'Snow tha Product',
    isPrincipal: true,
    order: 5
  },
  {
    key: 34,
    src: principalImages(`./snow_2.jpg`),
    alt: 'snow_2',
    title: 'Snow tha Product',
    isPrincipal: true,
    order: 5
  },
  {
    key: 35,
    src: principalImages(`./snow_3.jpg`),
    alt: 'snow_3',
    title: 'Snow tha Product',
    isPrincipal: true,
    order: 5
  },
  {
    key: 36,
    src: principalImages(`./snow_4.jpg`),
    alt: 'snow_4',
    title: 'Snow tha Product',
    isPrincipal: true,
    order: 5
  },
  {
    key: 37,
    src: principalImages(`./snow_5.jpg`),
    alt: 'snow_5',
    title: 'Snow tha Product',
    isPrincipal: true,
    order: 5
  },
  {
    key: 38,
    src: principalImages(`./snow_6.jpg`),
    alt: 'snow_6',
    title: 'Snow tha Product',
    isPrincipal: true,
    order: 5
  },
  {
    key: 39,
    src: principalImages(`./snow_7.jpg`),
    alt: 'snow_6',
    title: 'Snow tha Product',
    isPrincipal: true,
    order: 5
  },
  {
    key: 40,
    src: principalImages(`./snow_8.jpg`),
    alt: 'snow_8',
    title: 'Snow tha Product',
    isPrincipal: true,
    order: 5
  },
  {
    key: 41,
    src: principalImages(`./snow_9.jpg`),
    alt: 'snow_8',
    title: 'Snow tha Product',
    isPrincipal: true,
    order: 5
  },
  {
    key: 42,
    src: principalImages(`./snow_10.jpg`),
    alt: 'snow_10',
    title: 'Snow tha Product',
    isPrincipal: true,
    order: 5
  },
  {
    key: 43,
    src: principalImages(`./snow_11.jpg`),
    alt: 'snow_10',
    title: 'Snow tha Product',
    isPrincipal: true,
    order: 5
  },
  {
    key: 44,
    src: principalImages(`./snow_12.jpg`),
    alt: 'snow_12',
    title: 'Snow tha Product',
    isPrincipal: true,
    order: 5
  },
  {
    key: 45,
    src: otherImages(`./costa_1.jpg`),
    alt: 'costa_1',
    title: 'Costa',
    isPrincipal: false
  },
  {
    key: 46,
    src: otherImages(`./mFerrero_1.jpg`),
    alt: 'mFerrero_1',
    title: 'M. Ferero',
    isPrincipal: false
  },
  {
    key: 47,
    src: otherImages(`./mFerrero_2.jpg`),
    alt: 'mFerrero_2',
    title: 'M. Ferero',
    isPrincipal: false
  },
  {
    key: 48,
    src: otherImages(`./foto8.jpg`),
    alt: 'foto8',
    title: 'Blasfem',
    isPrincipal: false
  },
  {
    key: 49,
    src: otherImages(`./foto28.jpg`),
    alt: 'foto28',
    title: '',
    isPrincipal: false
  },
  {
    key: 50,
    src: otherImages(`./foto55.jpg`),
    alt: 'foto55',
    title: '',
    isPrincipal: false
  },
  {
    key: 51,
    src: principalImages(`./trueno_1.jpg`),
    alt: 'trueno_1',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 52,
    src: principalImages(`./trueno_2.jpg`),
    alt: 'trueno_2',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 53,
    src: principalImages(`./trueno_3.jpg`),
    alt: 'trueno_3',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 54,
    src: principalImages(`./trueno_4.jpg`),
    alt: 'trueno_4',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 55,
    src: principalImages(`./trueno_5.jpg`),
    alt: 'trueno_5',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 56,
    src: principalImages(`./trueno_6.jpg`),
    alt: 'trueno_6',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 57,
    src: principalImages(`./trueno_7.jpg`),
    alt: 'trueno_7',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 58,
    src: principalImages(`./trueno_8.jpg`),
    alt: 'trueno_8',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 59,
    src: principalImages(`./trueno_9.jpg`),
    alt: 'trueno_9',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 60,
    src: principalImages(`./trueno_10.jpg`),
    alt: 'trueno_10',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 61,
    src: principalImages(`./trueno_11.jpg`),
    alt: 'trueno_11',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 62,
    src: principalImages(`./trueno_12.jpg`),
    alt: 'trueno_12',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 63,
    src: principalImages(`./trueno_13.jpg`),
    alt: 'trueno_13',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 64,
    src: principalImages(`./trueno_14.jpg`),
    alt: 'trueno_14',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 65,
    src: principalImages(`./trueno_15.jpg`),
    alt: 'trueno_15',
    title: 'Trueno',
    isPrincipal: true,
    order: 3
  },
  {
    key: 66,
    src: otherImages(`./dudi_1.jpg`),
    alt: 'dudi_1',
    title: 'Dudi',
    isPrincipal: false
  },
  {
    key: 67,
    src: otherImages(`./dudi_2.jpg`),
    alt: 'dudi_2',
    title: 'Dudi',
    isPrincipal: false
  },
  {
    key: 68,
    src: otherImages(`./dudi_3.jpg`),
    alt: 'dudi_3',
    title: 'Dudi',
    isPrincipal: false
  },
  {
    key: 69,
    src: otherImages(`./dudi_4.jpg`),
    alt: 'dudi_4',
    title: 'Dudi',
    isPrincipal: false
  },
  {
    key: 70,
    src: otherImages(`./dudi_5.jpg`),
    alt: 'dudi_5',
    title: 'Dudi',
    isPrincipal: false
  },
  {
    key: 71,
    src: otherImages(`./dudi_6.jpg`),
    alt: 'dudi_6',
    title: 'Dudi',
    isPrincipal: false
  },
  {
    key: 72,
    src: otherImages(`./petazeta_1.jpg`),
    alt: 'petazeta_1',
    title: 'Petazeta',
    isPrincipal: false
  },
  {
    key: 73,
    src: otherImages(`./petazeta_2.jpg`),
    alt: 'petazeta_2',
    title: 'Petazeta',
    isPrincipal: false
  },
  {
    key: 74,
    src: otherImages(`./natos.jpg`),
    alt: 'natos',
    title: 'Natos',
    isPrincipal: false
  },
  {
    key: 75,
    src: otherImages(`./zero.jpg`),
    alt: 'zero',
    title: 'Zero',
    isPrincipal: false
  },
  {
    key: 76,
    src: otherImages(`./walls_1.jpg`),
    alt: 'walls_1',
    title: 'Walls',
    isPrincipal: false
  },
  {
    key: 77,
    src: otherImages(`./walls_2.jpg`),
    alt: 'walls_2',
    title: 'Walls',
    isPrincipal: false
  },
  {
    key: 78,
    src: otherImages(`./walls_3.jpg`),
    alt: 'walls_3',
    title: 'Walls',
    isPrincipal: false
  },
  {
    key: 79,
    src: otherImages(`./walls_4.jpg`),
    alt: 'walls_4',
    title: 'Walls',
    isPrincipal: false
  },
  {
    key: 80,
    src: principalImages(`./nicki_1.jpg`),
    alt: 'nicki_1',
    title: 'Nicki Nicole',
    isPrincipal: true,
    order: 7
  },
  {
    key: 81,
    src: principalImages(`./nicki_2.jpg`),
    alt: 'nicki_2',
    title: 'Nicki Nicole',
    isPrincipal: true,
    order: 7
  },
  {
    key: 82,
    src: principalImages(`./nicki_3.jpg`),
    alt: 'nicki_3',
    title: 'Nicki Nicole',
    isPrincipal: true,
    order: 7
  },
  {
    key: 83,
    src: principalImages(`./nicki_4.jpg`),
    alt: 'nicki_4',
    title: 'Nicki Nicole',
    isPrincipal: true,
    order: 7
  },
  {
    key: 84,
    src: principalImages(`./nicki_5.jpg`),
    alt: 'nicki_5',
    title: 'Nicki Nicole',
    isPrincipal: true,
    order: 7
  },
  {
    key: 85,
    src: principalImages(`./nicki_6.jpg`),
    alt: 'nicki_6',
    title: 'Nicki Nicole',
    isPrincipal: true,
    order: 7
  },
  {
    key: 86,
    src: principalImages(`./nicki_7.jpg`),
    alt: 'nicki_7',
    title: 'Nicki Nicole',
    isPrincipal: true,
    order: 7
  },
  {
    key: 87,
    src: principalImages(`./nicki_8.jpg`),
    alt: 'nicki_8',
    title: 'Nicki Nicole',
    isPrincipal: true,
    order: 7
  },
  {
    key: 88,
    src: principalImages(`./nicki_9.jpg`),
    alt: 'nicki_9',
    title: 'Nicki Nicole',
    isPrincipal: true,
    order: 7
  },
  {
    key: 89,
    src: principalImages(`./nicki_10.jpg`),
    alt: 'nicki_10',
    title: 'Nicki Nicole',
    isPrincipal: true,
    order: 7
  },
  {
    key: 90,
    src: principalImages(`./nicki_11.jpg`),
    alt: 'nicki_11',
    title: 'Nicki Nicole',
    isPrincipal: true,
    order: 7
  },
  {
    key: 91,
    src: principalImages(`./nicki_12.jpg`),
    alt: 'nicki_12',
    title: 'Nicki Nicole',
    isPrincipal: true,
    order: 7
  },
  {
    key: 92,
    src: principalImages(`./becerra_1.jpg`),
    alt: 'becerra_1',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 93,
    src: principalImages(`./becerra_2.jpg`),
    alt: 'becerra_2',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 94,
    src: principalImages(`./becerra_3.jpg`),
    alt: 'becerra_3',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 95,
    src: principalImages(`./becerra_4.jpg`),
    alt: 'becerra_4',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 96,
    src: principalImages(`./becerra_5.jpg`),
    alt: 'becerra_5',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 97,
    src: principalImages(`./becerra_6.jpg`),
    alt: 'becerra_6',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 98,
    src: principalImages(`./becerra_7.jpg`),
    alt: 'becerra_7',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 99,
    src: principalImages(`./becerra_8.jpg`),
    alt: 'becerra_8',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 100,
    src: principalImages(`./becerra_9.jpg`),
    alt: 'becerra_9',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 101,
    src: principalImages(`./becerra_10.jpg`),
    alt: 'becerra_10',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 102,
    src: principalImages(`./becerra_12.jpg`),
    alt: 'becerra_12',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 103,
    src: principalImages(`./becerra_4.jpg`),
    alt: 'becerra_14',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 104,
    src: principalImages(`./becerra_15.jpg`),
    alt: 'becerra_15',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 105,
    src: principalImages(`./becerra_16.jpg`),
    alt: 'becerra_16',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 107,
    src: principalImages(`./becerra_18.jpg`),
    alt: 'becerra_18',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 108,
    src: principalImages(`./becerra_19.jpg`),
    alt: 'becerra_19',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 109,
    src: principalImages(`./becerra_20.jpg`),
    alt: 'becerra_20',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 110,
    src: principalImages(`./becerra_21.jpg`),
    alt: 'becerra_21',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 111,
    src: principalImages(`./becerra_22.jpg`),
    alt: 'becerra_22',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 112,
    src: principalImages(`./becerra_26.jpg`),
    alt: 'becerra_26',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 113,
    src: principalImages(`./becerra_27.jpg`),
    alt: 'becerra_27',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 114,
    src: principalImages(`./becerra_28.jpg`),
    alt: 'becerra_28',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 115,
    src: principalImages(`./becerra_29.jpg`),
    alt: 'becerra_29',
    title: 'María Becerra',
    isPrincipal: true,
    order: 4
  },
  {
    key: 116,
    src: otherImages(`./nathyPeluso_1.jpg`),
    alt: 'nathyPeluso_1',
    title: 'Nathy Peluso',
    isPrincipal: false
  },
  {
    key: 117,
    src: otherImages(`./nathyPeluso_2.jpg`),
    alt: 'nathyPeluso_2',
    title: 'Nathy Peluso'
  },
  {
    key: 118,
    src: otherImages(`./ciro&LosPersas_1.jpg`),
    alt: 'ciro y los persas_1',
    title: 'Ciro y los Persas'
  },
  {
    key: 119,
    src: otherImages(`./ciro&LosPersas_2.jpg`),
    alt: 'ciro y los persas_2',
    title: 'Ciro y los Persas'
  },
  {
    key: 108,
    src: otherImages(`./ciro&LosPersas_3.jpg`),
    alt: 'ciro y los persas_3',
    title: 'Ciro y los Persas'
  },
  {
    key: 109,
    src: otherImages(`./ciro&LosPersas_4.jpg`),
    alt: 'ciro y los persas_4',
    title: 'Ciro y los Persas'
  },
  {
    key: 110,
    src: otherImages(`./ciro&LosPersas_5.jpg`),
    alt: 'ciro y los persas_5',
    title: 'Ciro y los Persas'
  },
  {
    key: 111,
    src: otherImages(`./ciro&LosPersas_6.jpg`),
    alt: 'ciro y los persas_6',
    title: 'Ciro y los Persas'
  },
  {
    key: 112,
    src: otherImages(`./ciro&LosPersas_7.jpg`),
    alt: 'ciro y los persas_7',
    title: 'Ciro y los Persas'
  },
  {
    key: 113,
    src: otherImages(`./ciro&LosPersas_8.jpg`),
    alt: 'ciro y los persas_8',
    title: 'Ciro y los Persas'
  },
  {
    key: 114,
    src: otherImages(`./ciro&LosPersas_9.jpg`),
    alt: 'ciro y los persas_9',
    title: 'Ciro y los Persas'
  },
  {
    key: 115,
    src: otherImages(`./ciro&LosPersas_10.jpg`),
    alt: 'ciro y los persas_10',
    title: 'Ciro y los Persas'
  },
  {
    key: 116,
    src: otherImages(`./ciro&LosPersas_11.jpg`),
    alt: 'ciro y los persas_11',
    title: 'Ciro y los Persas'
  },
  {
    key: 117,
    src: otherImages(`./ciro&LosPersas_12.jpg`),
    alt: 'ciro y los persas_12',
    title: 'Ciro y los Persas'
  },
  {
    key: 118,
    src: otherImages(`./ciro&LosPersas_13.jpg`),
    alt: 'ciro y los persas_13',
    title: 'Ciro y los Persas'
  },
  {
    key: 119,
    src: otherImages(`./ciro&LosPersas_14.jpg`),
    alt: 'ciro y los persas_14',
    title: 'Ciro y los Persas'
  },
  {
    key: 120,
    src: otherImages(`./ciro&LosPersas_15.jpg`),
    alt: 'ciro y los persas_15',
    title: 'Ciro y los Persas'
  },
  {
    key: 121,
    src: otherImages(`./ciro&LosPersas_16.jpg`),
    alt: 'ciro y los persas_16',
    title: 'Ciro y los Persas'
  },
  {
    key: 122,
    src: otherImages(`./ciro&LosPersas_17.jpg`),
    alt: 'ciro y los persas_17',
    title: 'Ciro y los Persas'
  },
  {
    key: 123,
    src: otherImages(`./ciro&LosPersas_18.jpg`),
    alt: 'ciro y los persas_18',
    title: 'Ciro y los Persas'
  },
  {
    key: 124,
    src: otherImages(`./ciro&LosPersas_19.jpg`),
    alt: 'ciro y los persas_19',
    title: 'Ciro y los Persas'
  },
  {
    key: 125,
    src: otherImages(`./ciro&LosPersas_20.jpg`),
    alt: 'ciro y los persas_20',
    title: 'Ciro y los Persas'
  },
  {
    key: 125,
    src: otherImages(`./ciro&LosPersas_20.jpg`),
    alt: 'ciro y los persas_20',
    title: 'Ciro y los Persas'
  },
  {
    key: 126,
    src: principalImages(`./beckyG_collage.jpg`),
    alt: 'Becky G collage',
    title: ''
  },
  {
    key: 127,
    src: principalImages(`./snow_collage.jpg`),
    alt: 'Snow tha Product collage',
    title: ''
  },
  {
    key: 128,
    src: principalImages(`./nicki_collage.jpg`),
    alt: 'Nicki Nicole',
    title: ''
  },
  {
    key: 129,
    src: otherImages(`./ana_mena_1.jpg`),
    alt: 'Ana Mena',
    title: ''
  },
  {
    key: 130,
    src: otherImages(`./ana_mena_2.jpg`),
    alt: 'Ana Mena',
    title: ''
  },
  {
    key: 131,
    src: otherImages(`./ana_mena_3.jpg`),
    alt: 'Ana Mena',
    title: ''
  },
  {
    key: 132,
    src: otherImages(`./ana_mena_4.jpg`),
    alt: 'Ana Mena',
    title: ''
  },
  {
    key: 133,
    src: otherImages(`./ana_mena_5.jpg`),
    alt: 'Ana Mena',
    title: ''
  },
  {
    key: 134,
    src: otherImages(`./ana_mena_6.jpg`),
    alt: 'Ana Mena',
    title: ''
  },
  {
    key: 135,
    src: otherImages(`./ana_mena_7.jpg`),
    alt: 'Ana Mena',
    title: ''
  },
  {
    key: 136,
    src: otherImages(`./ana_mena_8.jpg`),
    alt: 'Ana Mena',
    title: ''
  },
  {
    key: 137,
    src: otherImages(`./ana_mena_9.jpg`),
    alt: 'Ana Mena',
    title: ''
  },
  {
    key: 38,
    src: otherImages(`./ana_mena_10.jpg`),
    alt: 'Ana Mena',
    title: ''
  },
  {
    key: 39,
    src: otherImages(`./sfx_feid_1.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 40,
    src: otherImages(`./sfx_feid_2.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 41,
    src: otherImages(`./sfx_feid_3.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 42,
    src: otherImages(`./sfx_feid_4.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 43,
    src: otherImages(`./sfx_feid_5.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 44,
    src: otherImages(`./sfx_feid_6.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 45,
    src: otherImages(`./sfx_feid_7.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 46,
    src: otherImages(`./sfx_feid_8.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 47,
    src: otherImages(`./sfx_feid_9.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 48,
    src: otherImages(`./sfx_feid_10.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 49,
    src: otherImages(`./sfx_feid_11.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 50,
    src: otherImages(`./sfx_feid_12.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 51,
    src: otherImages(`./sfx_feid_13.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 52,
    src: otherImages(`./sfx_feid_14.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 53,
    src: otherImages(`./sfx_feid_15.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 54,
    src: otherImages(`./sfx_feid_16.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 55,
    src: otherImages(`./sfx_feid_17.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 56,
    src: otherImages(`./sfx_feid_18.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 57,
    src: otherImages(`./sfx_feid_19.jpg`),
    alt: 'sfx Feid',
    title: ''
  },
  {
    key: 58,
    src: otherImages(`./sfx_feid_20.jpg`),
    alt: 'sfx Feid',
    title: ''
  }
];
