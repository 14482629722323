import * as React from 'react';
import ReactPlayer from 'react-player';
import { Col, Row } from 'reactstrap';
import miniatura1 from '../../assets/images/miniatura-videos/miniatura1.jpg';
import miniatura2 from '../../assets/images/miniatura-videos/miniatura2.jpg';
import miniatura3 from '../../assets/images/miniatura-videos/miniatura3.jpg';
import miniatura4 from '../../assets/images/miniatura-videos/miniatura4.jpg';
import './Video.css';

export const Video = () => {
  const items = [
    {
      key: 1,
      url: 'https://youtu.be/OpqNK7Fcd2Y',
      src: miniatura3,
      title: 'Entre Tragos - UnFamous (Prod. Alex Lumbier) (Vídeo Oficial)',
      alt: 'Entre Tragos - UnFamous (Prod. Alex Lumbier) (Vídeo Oficial)'
    },
    {
      key: 2,
      url: 'https://www.youtube.com/watch?v=TRR7UqYZ2aQ&ab_channel=%C3%81ngelCruz',
      src: miniatura1,
      title: 'Angel Cruz - Parte de tu piel',
      alt: 'Angel Cruz - Parte de tu piel'
    },
    {
      key: 3,
      url: 'https://www.youtube.com/watch?v=AOVEoSmP_6U',
      src: miniatura2,
      title: 'Chipi Rogles - VOLARÉ',
      alt: 'Chipi Rogles - VOLARÉ'
    },
    {
      key: 4,
      url: 'https://www.youtube.com/watch?v=d2XOMqnW_LY',
      src: miniatura4,
      title: '𝕄 𝕀 ℝ 𝕀 𝔸 𝕄 - Hasta Marte (Videoclip Oficial)',
      alt: '𝕄 𝕀 ℝ 𝕀 𝔸 𝕄 - Hasta Marte (Videoclip Oficial)'
    }
  ];

  const matches = window.matchMedia('(min-width: 768px)').matches;
  const [videoFilePath, setVideoFilePath] = React.useState(items[0].url);
  // const [isPhone, setIsPhone] = React.useState(matches);

  // React.useEffect(() => {
  //   const handler = (e) => setIsPhone(e.matches);
  //   window.matchMedia('(min-width: 768px)').addEventListener('change', handler);
  // }, [isPhone]);

  return matches ? (
    <>
      <div className="video-container">
        <ReactPlayer
          url={videoFilePath}
          className="react-player"
          width="100%"
          height="100%"
          controls
        />
      </div>
      <Row className="video-gallery-container">
        {items.map((item) => (
          <Col
            key={item.key}
            className="video-btn col-3"
            onClick={() => setVideoFilePath(item.url)}
          >
            <img src={item.src} alt={item.alt} className="video-img" />
            <p className="video-img-text">{item.title}</p>
          </Col>
        ))}
      </Row>
    </>
  ) : (
    <div className="video-container">
      {items.map((item) => (
        <ReactPlayer
          key={item.key}
          url={item.url}
          className="react-player"
          width="100%"
          height="100%"
          controls
        />
      ))}
    </div>
  );
};
