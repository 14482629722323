import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import './Portfolio.css';

import { photos } from './events/photos';
import { portraits } from './retratos/portrait_images';
import { vfx_images } from './vfx/vfx_images';

export const Portfolio = () => {
  const navigate = useNavigate();

  const GoToPage = (section) => {
    if (section === 'events') {
      navigate('/portfolio/events');
    } else if (section === 'portraits') {
      navigate('/portfolio/portrait');
    } else if (section === 'vfx') {
      navigate('/portfolio/vfx');
    }
  };

  const artistImage = photos.filter((x) => x.title === 'Quevedo')[5];
  const portraitImage = portraits[0];
  const vfxImage = vfx_images.filter((x) =>
    x.src.includes('scarlet-witch.')
  )[0];

  return (
    <div className="portfolio-container">
      <div
        className="section-card"
        key={1}
        style={{
          backgroundImage: `url('${artistImage.src}')`
        }}
        onClick={() => GoToPage('events')}
      >
        <div className="section-title">
          <h3>Eventos</h3>
        </div>
      </div>
      <div
        className="section-card"
        key={2}
        style={{
          backgroundImage: `url('${portraitImage.src}')`
        }}
        onClick={() => GoToPage('portraits')}
      >
        <div className="section-title">
          <h3>Retratos</h3>
        </div>
      </div>
      <div
        className="section-card"
        key={2}
        style={{
          backgroundImage: `url('${vfxImage.src}')`
        }}
        onClick={() => GoToPage('vfx')}
      >
        <div className="section-title">
          <h3>Photo manipulation</h3>
        </div>
      </div>
    </div>
  );
};
