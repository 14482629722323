import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { photos } from './photos';
import './Events.css';

export const EventSection = () => {
  const [principalArtistImages] = React.useState(
    photos
      .filter((photo) => photo.isPrincipal && photo.src.includes('_1.'))
      .sort(function (a, b) {
        return a.order - b.order;
      })
  );
  const [otherArtistImages, setOtherArtistImages] = React.useState(
    photos.filter(
      (photo) => !photo.isPrincipal && !photo.src.includes('collage.')
    )
  );
  const navigate = useNavigate();

  useEffect(() => {
    principalArtistImages.map((principal) => {
      const items = otherArtistImages.filter(
        (photo) => photo.title !== principal.title
      );
      setOtherArtistImages(items);
    });
  }, []);

  const OnOpenGallery = (title) => {
    if (title != null) {
      navigate('/portfolio/events/artist', {
        state: { images: photos.filter((x) => x.title === title) }
      });
    } else {
      navigate('/portfolio/events/artist', {
        state: { images: otherArtistImages }
      });
    }
  };

  return (
    <div className="artists-container container">
      {principalArtistImages.map((photo, index) => (
        <div
          className="artist-card"
          key={index}
          style={{
            backgroundImage: `url('${photo.src}')`
          }}
          onClick={() => OnOpenGallery(photo.title)}
        >
          <div className="artist-title">
            <h3>{photo.title}</h3>
          </div>
        </div>
      ))}
      <div
        className="artist-card"
        key={1}
        style={{
          backgroundImage: `url('${otherArtistImages[0].src}')`
        }}
        onClick={() => OnOpenGallery()}
      >
        <div className="artist-title">
          <h3>Más artistas</h3>
        </div>
      </div>
    </div>
  );
};
