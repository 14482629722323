import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { NavBar } from './utils/NavBar'
import { Home } from './home/Home'
import { Video } from './video/Video'
import { PortraitSection } from './portfolio/retratos/PortraitSection'
import { EventSection } from './portfolio/events/EventSection'
import { ArtistSection } from './portfolio/events/artists/ArtistSection'
import { Portfolio } from './portfolio/Portfolio'
import { VFXSection } from './portfolio/vfx/VFXSection'

function App () {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/video' element={<Video />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/portfolio/events' element={<EventSection />} />
        <Route path='/portfolio/portrait' element={<PortraitSection />} />
        <Route path='/portfolio/vfx' element={<VFXSection />} />
        <Route path='/portfolio/events/artist' element={<ArtistSection />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
