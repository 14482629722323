const images = require.context('../../../assets/images/retratos', true);
const halloweenImages = require.context(
  '../../../assets/images/retratos/halloween',
  true
);

export const portraits = [
  // {
  //   key: 44,
  //   src: images(`./portrait_1.jpg`),
  //   alt: 'portrait 1'
  // },
  // {
  //   key: 45,
  //   src: images(`./portrait_2.jpg`),
  //   alt: 'portrait 2'
  // },
  // {
  //   key: 46,
  //   src: images(`./portrait_3.jpg`),
  //   alt: 'portrait 3'
  // },
  // {
  //   key: 47,
  //   src: images(`./portrait_4.jpg`),
  //   alt: 'portrait 4'
  // },
  // {
  //   key: 48,
  //   src: images(`./portrait_5.jpg`),
  //   alt: 'portrait 5'
  // },
  // {
  //   key: 49,
  //   src: images(`./portrait_6.jpg`),
  //   alt: 'portrait 6'
  // },
  {
    key: 1,
    src: images(`./retrato_1.jpg`),
    alt: 'retrato 1'
  },
  {
    key: 2,
    src: images(`./retrato_2.jpg`),
    alt: 'retrato 2'
  },
  {
    key: 3,
    src: images(`./retrato_3.jpg`),
    alt: 'retrato 3'
  },
  {
    key: 4,
    src: images(`./retrato_4.jpg`),
    alt: 'retrato 4'
  },
  {
    key: 5,
    src: images(`./retrato_5.jpg`),
    alt: 'retrato 5'
  },
  {
    key: 6,
    src: images(`./retrato_6.jpg`),
    alt: 'retrato 6'
  },
  {
    key: 7,
    src: images(`./retrato_7.jpg`),
    alt: 'retrato 7'
  },
  {
    key: 8,
    src: images(`./retrato_8.jpg`),
    alt: 'retrato 8'
  },
  {
    key: 9,
    src: images(`./retrato_9.jpg`),
    alt: 'retrato 9'
  },
  {
    key: 10,
    src: images(`./retrato_10.jpg`),
    alt: 'retrato 10'
  },
  {
    key: 11,
    src: images(`./retrato_11.jpg`),
    alt: 'retrato 11'
  },
  {
    key: 12,
    src: images(`./retrato_12.jpg`),
    alt: 'retrato 12'
  },
  {
    key: 13,
    src: images(`./retrato_13.jpg`),
    alt: 'retrato 13'
  },
  {
    key: 14,
    src: images(`./retrato_14.jpg`),
    alt: 'retrato 14'
  },
  {
    key: 15,
    src: images(`./retrato_15.jpg`),
    alt: 'retrato 15'
  },
  {
    key: 16,
    src: images(`./retrato_16.jpg`),
    alt: 'retrato 16'
  },
  {
    key: 17,
    src: images(`./retrato_17.jpg`),
    alt: 'retrato 17'
  },
  {
    key: 18,
    src: images(`./retrato_18.jpg`),
    alt: 'retrato 18'
  },
  {
    key: 19,
    src: images(`./retrato_19.jpg`),
    alt: 'retrato 19'
  },
  {
    key: 20,
    src: images(`./retrato_20.jpg`),
    alt: 'retrato 20'
  },
  {
    key: 21,
    src: images(`./retrato_21.jpg`),
    alt: 'retrato 21'
  },
  {
    key: 22,
    src: images(`./retrato_22.jpg`),
    alt: 'retrato 22'
  },
  {
    key: 50,
    src: images(`./portrait_7.jpg`),
    alt: 'portrait 7'
  },
  {
    key: 51,
    src: images(`./portrait_8.jpg`),
    alt: 'portrait 8'
  },
  {
    key: 52,
    src: images(`./portrait_9.jpg`),
    alt: 'portrait 9'
  },
  {
    key: 53,
    src: images(`./portrait_10.jpg`),
    alt: 'portrait 10'
  },
  {
    key: 54,
    src: images(`./portrait_11.jpg`),
    alt: 'portrait 11'
  },
  {
    key: 55,
    src: images(`./portrait_12.jpg`),
    alt: 'portrait 12'
  },
  {
    key: 56,
    src: images(`./portrait_13.jpg`),
    alt: 'portrait 13'
  },
  {
    key: 57,
    src: images(`./portrait_14.jpg`),
    alt: 'portrait 14'
  },
  {
    key: 58,
    src: images(`./retrato_23.jpg`),
    alt: 'retrato 23'
  },
  {
    key: 59,
    src: images(`./retrato_24.jpg`),
    alt: 'retrato 24'
  },
  {
    key: 60,
    src: images(`./retrato_25.jpg`),
    alt: 'retrato 25'
  },
  {
    key: 61,
    src: images(`./retrato_26.jpg`),
    alt: 'retrato 26'
  },
  {
    key: 62,
    src: images(`./retrato_27.jpg`),
    alt: 'retrato 27'
  },
  {
    key: 23,
    src: halloweenImages(`./halloween_1.jpg`),
    alt: 'halloween 1'
  },
  {
    key: 24,
    src: halloweenImages(`./halloween_2.jpg`),
    alt: 'halloween 2'
  },
  {
    key: 25,
    src: halloweenImages(`./halloween_3.jpg`),
    alt: 'halloween 3'
  },
  {
    key: 26,
    src: halloweenImages(`./halloween_4.jpg`),
    alt: 'halloween 4'
  },
  {
    key: 27,
    src: halloweenImages(`./halloween_5.jpg`),
    alt: 'halloween 5'
  },
  {
    key: 28,
    src: halloweenImages(`./halloween_6.jpg`),
    alt: 'halloween 6'
  },
  {
    key: 29,
    src: halloweenImages(`./halloween_7.jpg`),
    alt: 'halloween 7'
  },
  {
    key: 30,
    src: halloweenImages(`./halloween_8.jpg`),
    alt: 'halloween 8'
  },
  {
    key: 31,
    src: halloweenImages(`./halloween_9.jpg`),
    alt: 'halloween 9'
  },
  {
    key: 32,
    src: halloweenImages(`./halloween_10.jpg`),
    alt: 'halloween 10'
  },
  {
    key: 33,
    src: halloweenImages(`./halloween_11.jpg`),
    alt: 'halloween 11'
  },
  {
    key: 34,
    src: halloweenImages(`./halloween_12.jpg`),
    alt: 'halloween 12'
  },
  {
    key: 35,
    src: halloweenImages(`./halloween_13.jpg`),
    alt: 'halloween 13'
  },
  {
    key: 36,
    src: halloweenImages(`./halloween_14.jpg`),
    alt: 'halloween 14'
  },
  {
    key: 37,
    src: halloweenImages(`./halloween_15.jpg`),
    alt: 'halloween 15'
  },
  {
    key: 38,
    src: halloweenImages(`./halloween_16.jpg`),
    alt: 'halloween 16'
  },
  {
    key: 39,
    src: halloweenImages(`./halloween_17.jpg`),
    alt: 'halloween 17'
  },
  {
    key: 40,
    src: halloweenImages(`./halloween_18.jpg`),
    alt: 'halloween 18'
  },
  {
    key: 41,
    src: halloweenImages(`./halloween_19.jpg`),
    alt: 'halloween 19'
  },
  {
    key: 42,
    src: halloweenImages(`./halloween_20.jpg`),
    alt: 'halloween 20'
  },
  {
    key: 43,
    src: halloweenImages(`./halloween_21.jpg`),
    alt: 'halloween 21'
  },
  {
    key: 44,
    src: images(`./boxing_4.jpg`),
    alt: 'boxing 1'
  },
  {
    key: 45,
    src: images(`./boxing_5.jpg`),
    alt: 'boxing 2'
  },
  {
    key: 46,
    src: images(`./boxing_6.jpg`),
    alt: 'boxing 3'
  },
  {
    key: 47,
    src: images(`./boxing_7.jpg`),
    alt: 'boxing 4'
  },
  {
    key: 48,
    src: images(`./boxing_8.jpg`),
    alt: 'boxing 5'
  },
  {
    key: 49,
    src: images(`./boxing_9.jpg`),
    alt: 'boxing 6'
  },
  {
    key: 50,
    src: images(`./boxing_10.jpg`),
    alt: 'boxing 7'
  },
  {
    key: 51,
    src: images(`./boxing_11.jpg`),
    alt: 'boxing 8'
  },
  {
    key: 52,
    src: images(`./boxing_12.jpg`),
    alt: 'boxing 9'
  },
  {
    key: 53,
    src: images(`./boxing_13.jpg`),
    alt: 'boxing 10'
  },
  {
    key: 54,
    src: images(`./boxing_14.jpg`),
    alt: 'boxing 11'
  },
  {
    key: 55,
    src: images(`./boxing_15.jpg`),
    alt: 'boxing 12'
  }
];
