import * as React from 'react';
import { NavLink } from 'reactstrap';
import { FaEnvelope } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaTiktok } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FaCamera } from 'react-icons/fa';
import { SiAdobephotoshop } from 'react-icons/si';
import { BsCardImage } from 'react-icons/bs';
import { IoColorFilterSharp } from 'react-icons/io5';
import { FaPinterest } from 'react-icons/fa';
import { FaEtsy } from 'react-icons/fa';

import './Home.css';

import { Slider } from '../utils/Slider';

import { photos } from '../portfolio/events/photos';
import { useEffect } from 'react';

const images = require.context('../../assets/images', true);
const contentImages = require.context('../../assets/images/content', true);

export const Home = () => {
  const matches = window.matchMedia('(min-width: 768px)').matches;
  const [isPhone, setIsPhone] = React.useState(matches);

  useEffect(() => {
    const handler = (e) => setIsPhone(e.matches);
    window.matchMedia('(min-width: 768px)').addEventListener('change', handler);
  }, [isPhone]);

  function OpenWebSlider() {
    var slider1 = photos.filter((photo) =>
      photo.src.includes('beckyG_collage.')
    )[0];
    var slider2 = photos.filter((photo) =>
      photo.src.includes('snow_collage.')
    )[0];
    var slider3 = photos.filter((photo) =>
      photo.src.includes('nicki_collage.')
    )[0];

    return (
      <Slider images={[slider1, slider2, slider3]} showIndicators={false} />
    );
  }

  function OpenPhoneSlider() {
    var slider1 = photos.filter((photo) => photo.src.includes('tangana_1.'))[0];
    var slider2 = photos.filter((photo) => photo.src.includes('quevedo_1.'))[0];
    var slider3 = photos.filter((photo) => photo.src.includes('beckyG_1.'))[0];
    var slider4 = photos.filter((photo) => photo.src.includes('becerra_1.'))[0];
    var slider5 = photos.filter((photo) => photo.src.includes('trueno_1.'))[0];

    return (
      <Slider
        images={[slider1, slider2, slider3, slider4, slider5]}
        showIndicators={false}
      />
    );
  }

  return (
    <>
      <section className="home">
        <div className="home-img">
          <img src={images(`./fondo.png`)} alt="Fotógrafo de eventos"></img>
        </div>
        <div className="home-content">
          <p className="title">Hola! Soy Pedro Gavilán</p>
          <div className="description">
            <div className="description-item">
              <div className="item_icon">
                <FaCamera />
              </div>
              <div className="item_title">
                <h1>Fotógrafo de eventos</h1>
              </div>
            </div>
            <div className="description-item">
              <div className="item_icon">
                <BsCardImage />
              </div>
              <div className="item_title">
                <h2>Matte painting y composición</h2>
              </div>
            </div>
            <div className="description-item">
              <div className="item_icon">
                <SiAdobephotoshop />
              </div>
              <div className="item_title">
                <h2>Especializado en edición de fotografía</h2>
              </div>
            </div>
            <div className="description-item">
              <div className="item_icon">
                <IoColorFilterSharp />
              </div>
              <div className="item_title">
                <h2>Corrección de color</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="content-item">
        <div className="section_color">
          <h2>Corrección de color</h2>
          <div className="section_color_items">
            <div
              className="section-color-card"
              key={1}
              style={{
                backgroundImage: `url('${contentImages(`./davs.jpg`)}')`
              }}
            ></div>
            <div
              className="section-color-card"
              key={2}
              style={{
                backgroundImage: `url('${contentImages(`./naiki.jpg`)}')`
              }}
            ></div>
          </div>
        </div>
        <div className="section_color">
          <h2>Photo manipulation</h2>
          <div className="section_color_items">
            <div
              className="section-color-card"
              key={1}
              style={{
                backgroundImage: `url('${contentImages(`./twd.gif`)}')`
              }}
            ></div>
            <div
              className="section-color-card"
              key={2}
              style={{
                backgroundImage: `url('${contentImages(`./boxeo.gif`)}')`
              }}
            ></div>
          </div>
        </div>
      </div>

      <div className="home-slider">
        {matches ? OpenWebSlider() : OpenPhoneSlider()}
      </div>

      <div className="myFooter">
        <NavLink
          className="footerItem"
          href="https://www.instagram.com/piter.gp/?utm_medium=copy_link"
          alt="enlace a instagram"
        >
          <FaInstagram />
          <span>instagram</span>
        </NavLink>
        <NavLink
          className="footerItem"
          href="https://www.tiktok.com/@photopiter"
          alt="enlace a tiktok"
        >
          <FaTiktok />
          <span>tiktok</span>
        </NavLink>
        <NavLink
          className="footerItem"
          href="https://www.youtube.com/@photopiterart/videos"
          alt="enlace a youtube"
        >
          <FaYoutube />
          <span>youtube</span>
        </NavLink>
        <NavLink
          className="footerItem"
          href="https://www.pinterest.es/Photopiter/"
          alt="enlace a pinterest"
        >
          <FaPinterest />
          <span>pinterest</span>
        </NavLink>
        <NavLink
          className="footerItem"
          href=" https://www.etsy.com/es/shop/fotopiter?ref=seller-platform-mcnav"
          alt="enlace a etsy"
        >
          <FaEtsy />
          <span>etsy</span>
        </NavLink>
        <NavLink
          className="footerItem"
          href="mailto:piter@fotopiter.com"
          alt="enlace al correo electrónico"
        >
          <FaEnvelope />
          <span>piter@fotopiter.com</span>
        </NavLink>
      </div>
    </>
  );
};
